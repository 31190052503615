import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { FALLBACK_IMAGE_URL } from "@/app/constants.mjs";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import {
  MatchPageContainer,
  MatchStatContainer,
  Subtitle,
} from "@/shared/Match.style.jsx";
import MatchGraph from "@/shared/MatchGraph.jsx";
import { PerfGrid } from "@/shared/Performance.style.jsx";
import PerformanceCard from "@/shared/PerformanceCard.jsx";
import ShareButton from "@/shared/ShareButton.jsx";
import SimpleLine from "@/shared/SimpleLineChart.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Match() {
  const state = useSnapshot(readState);
  const { t } = useTranslation();
  const {
    parameters: [matchId],
  } = useRoute();

  const match = state.unknown.matches[matchId];

  const matchError = match instanceof Error ? match : null;

  return (
    <SharedMatchLayout
      match={match}
      title={
        !match
          ? t("common:loading", "Loading...")
          : match.isWinner
            ? t("lol:postmatch.victory", "Victory")
            : t("lol:postmatch.defeat", "Defeat")
      }
      image={FALLBACK_IMAGE_URL}
      borderColor={
        !match ? "var(--shade3)" : match.isWinner ? "var(--turq)" : "var(--red)"
      }
      underTitle={
        <Subtitle>
          <TimeAgo date={match?.timestamp} />
          <ShareButton />
        </Subtitle>
      }
    >
      <MatchPageContainer>
        {match && !matchError ? <MatchFragments match={match} /> : null}
      </MatchPageContainer>
    </SharedMatchLayout>
  );
}

function MatchFragments({ match }) {
  // Here we just make up shiet, what it should really do is
  // derive data based on the match.
  const combatScore = Math.random();
  const farmingScore = Math.random();
  const visionScore = Math.random();

  const combatLabel = ["unknown:Combat", "Combat"];
  const farmingLabel = ["unknown:Farming", "Farming"];
  const visionLabel = ["unknown:Vision", "Vision"];

  const sampleContent = useCallback(() => {
    return <SampleContent match={match} />;
  }, [match]);

  const tabs = [
    {
      label: combatLabel,
      score: (combatScore * 100).toFixed(),
      content: sampleContent,
    },
    {
      label: farmingLabel,
      score: (farmingScore * 100).toFixed(),
      content() {
        return "fk2";
      },
    },
    {
      label: visionLabel,
      score: (visionScore * 100).toFixed(),
      content() {
        return "fk3";
      },
    },
  ];

  // const stats = new Array(2).fill(null).map((_, i) => {
  //   const score = Math.random() * 100;
  //   const fill = score / 100;

  //   return {
  //     label: ["unknown:stat", "Statistic (filler text)"],
  //     description: ["unknown:stat", "Statistic (filler text)"],
  //     value: 420,
  //     benchmarkValue: 666,
  //     min: 0,
  //     key: i,
  //     benchmark: new Array(20).fill(null).map(() => ({
  //       val: Math.random() * 100,
  //       stdev: Math.random() * 10,
  //     })),
  //     byMinute: new Array(20).fill(null).map(() => Math.random() * 100),
  //     score,
  //     fill,
  //   };
  // });

  return (
    <>
      <MatchStatContainer>
        <PerfGrid>
          <PerformanceCard score={combatScore} stats={[]} />
          <PerformanceCard score={farmingScore} stats={[]} />
          <PerformanceCard score={visionScore} stats={[]} />
        </PerfGrid>
      </MatchStatContainer>
      <MatchGraph tabs={tabs} />
    </>
  );
}

const Filters = styled("div")`
  display: flex;
  justify-content: space-between;
`;

function SampleContent(/*{ match }*/) {
  // This is just bogus shiet.
  const options = [
    {
      value: "GPM",
      text: ["overviewStats.goldPerMin", "Gold/Min."],
    },
    {
      value: "CSM",
      text: ["overviewStats.csPerMin", "CS/Min."],
    },
  ];
  const [selected, setSelected] = useState(options[0].value);

  const data = new Array(30).fill().map((_, i) => {
    return {
      x: i,
      y: (100 * Math.random()).toFixed(),
    };
  });

  const chartConfig = {
    margin: { left: 60, right: 20, top: 20, bottom: 40 },
    height: 320,
    xAxisConf: {
      visible: true,
      ticks: 15,
      tickRenderer: (xValue) => {
        return xValue.toFixed();
      },
    },
    yAxisConf: {
      visible: true,
      ticks: 7,
      tickRenderer: (yValue) => {
        return yValue.toFixed();
      },
    },
  };

  return (
    <>
      <Filters>
        <Select
          selected={selected}
          options={options}
          onChange={(v) => setSelected(v)}
          inputStyle
        />
      </Filters>
      <SimpleLine
        margin={chartConfig.margin}
        data={data}
        xField="x"
        yField="y"
        height={chartConfig.height}
        xAxisConf={chartConfig.xAxisConf}
        yAxisConf={chartConfig.yAxisConf}
        color={"var(--turq)"}
        circleRadius={5}
        showGridLines={true}
      />
    </>
  );
}

export function meta([id]) {
  return {
    title: ["common:unknownMatch", "unknown match {{id}}", { id }],
    description: ["common:unknownMatchDescription", "unknown match"],
  };
}

export default Match;
